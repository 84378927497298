//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
    ViewRef,
} from "@angular/core";
import { Location } from "@angular/common";
import { EventService } from "../../services/event.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { HierarchyUtil } from "./s25.event.type.hierarchy.util";
import { EventTypeFormI } from "../../pojo/HierarchyI";
import { LockService } from "../../services/lock.service";
import { Api } from "../../api/api";
import { DropdownApi } from "../s25-dropdown/dropdown.api";
//TODO: figure out why loading-inline is causing error on prod-all compile, commented out for now
import { S25LoadingApi } from "../s25-loading/loading.api";
import { AlertService } from "../../services/alert.service";

export class CabinetFolderApi extends Api {
    static refresh(relativeElem: HTMLElement) {
        return Api.callApiFn(relativeElem, "s25-ng-cabinet-folder", null, null, (comp: S25CabinetFolderComponent) => {
            comp && comp.ngOnInit();
        });
    }
}
@TypeManagerDecorator("s25-ng-cabinet-folder")
@Component({
    selector: "s25-ng-cabinet-folder",
    template: `
        <!--<s25-loading-inline model="{}" class="c-margin-top--single"></s25-loading-inline>-->
        @if (this.init && this.eventType.type_name && this.type != "view") {
            <div>
                <h3 class="c-margin-top--half">{{ this.isEdit ? "Edit" : "New" }} {{ this.name }} Type</h3>
                <!-- Don't think we're going to need these since I'm migrating the right click menu, but wanted to have theme just in case -->
                <!--<button *ngIf="this.type === 'C' && this.isEdit && this.typeId" (click)="this.createNewFolderType(this.eventType)" class="aw-button aw-button--outline">Add Folder Type</button><br/>-->
                <!--<button *ngIf="this.type === 'F' && this.isEdit && this.typeId" (click)="" class="aw-button aw-button--outline">Create Event Type Type</button>-->
                @if (this.type === "F" && this.cabinets && !this.isEdit && !this.isCopy) {
                    <div>
                        @if (!this.parentName) {
                            <label class="c-margin-right--single c-margin-top--half">
                                <span class="ngBlock c-margin-bottom--quarter" id="cabinet"
                                    ><span class="ngBold">Cabinet Type</span
                                    ><span class="c-required">- Required</span></span
                                >
                                <s25-generic-dropdown
                                    [items]="this.cabinets"
                                    [placeholder]="'Select a Cabinet Type'"
                                    [searchEnabled]="true"
                                    [(chosen)]="this.chosenCabinet"
                                    (chosenChange)="onChangeCabinet($event)"
                                ></s25-generic-dropdown>
                            </label>
                        }
                        @if (this.showSubFolder) {
                            <div>
                                <span
                                    id="create-new-layout-btn"
                                    class="c-margin-top--half c-margin-bottom--half c-margin-right--half ngBold ngInlineBlock"
                                    >Create Folder</span
                                >
                                <s25-toggle-button
                                    (modelValueChange)="this.getSubFolder($event)"
                                    [modelValue]="this.toggleFolderLevel"
                                ></s25-toggle-button>
                                @if (this.toggleFolderLevel) {
                                    <div>
                                        <label class="c-margin-right--single">
                                            <span class="ngBold ngBlock c-margin-bottom--quarter" id="cabinet"
                                                >Add Folder To</span
                                            >
                                            <s25-ng-hierarch-dropdown
                                                [items]="this.folders"
                                                [searchEnabled]="true"
                                                [(chosen)]="this.chosenFolder"
                                                (chosenChange)="onChangeFolder($event)"
                                            ></s25-ng-hierarch-dropdown>
                                        </label>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
                <label class="c-margin-right--single c-margin-top--half" for="typeName">
                    <span class="ngBlock c-margin-bottom--quarter"
                        ><span class="ngBold">{{ this.name }} Type Name </span
                        ><span class="c-required">- Required</span></span
                    >
                    <input
                        class="c-input"
                        id="typeName"
                        [value]="this.eventType.type_name"
                        (change)="onChangeName($event)"
                        type="text"
                        maxlength="80"
                        class="cn-form__control"
                    />
                </label>
                <div class="c-displayBlock c-margin-top--single">
                    <button
                        class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                        (click)="save()"
                        [disabled]="!this.eventType.type_name"
                    >
                        {{ this.loading ? "Saving...." : "Save" }}
                    </button>
                    @if (this.isEdit) {
                        <button
                            class="aw-button aw-button--danger--outline c-margin-top--single c-margin-right--quarter"
                            (click)="confirm()"
                            [disabled]="this.children"
                        >
                            Delete
                        </button>
                    }
                    @if (this.isEdit) {
                        <button
                            class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                            (click)="deactive()"
                            [disabled]="this.children"
                        >
                            Deactivate
                        </button>
                    }
                    @if (this.isEdit) {
                        <button
                            class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                            (click)="copy()"
                        >
                            Copy
                        </button>
                    }
                    <button
                        class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                        (click)="cancel()"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        }

        @if (this.deleteConfirmed) {
            <div>
                <s25-ng-event-type-delete
                    (modelValueChange)="this.onChangeDeleteConfirm($event)"
                ></s25-ng-event-type-delete>
            </div>
        }

        <!-- cancel button if new got back the list -->
        @if (this.init && this.type === "view") {
            <div>
                <s25-ng-event-type-hierarchy resetType="true"></s25-ng-event-type-hierarchy>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25CabinetFolderComponent implements OnInit {
    @Input() isEdit: any;
    @Input() type: string = "C";
    @Input() typeId: any;
    init = false;
    loading = false;
    allTypes: any;
    cabinets: any;
    parentId: number = 0;
    name: any;
    chosenCabinet: any;
    parentName: string = null;
    msg: any;
    eventType: EventTypeFormI = undefined;
    valid: boolean = true;
    children: boolean = false;
    isCopy: boolean = false;
    folders: any;
    chosenFolder: any;
    showSubFolder: boolean = false;
    hasSubFolder: boolean = false;
    selectedCabinet: any;
    toggleFolderLevel: boolean = false;
    getFolders: boolean = false;
    warningMsg: any;
    deleteConfirmed: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
        private location: Location,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        this.type === "C" ? (this.name = "Cabinet") : (this.name = "Folder");

        this.zone.run(() => {
            Promise.all([
                this.getAllEventTypes(),
                !this.isEdit ? this.postEventType() : this.getEventTypeById(this.typeId),
            ]).then((resp) => {
                this.init = true;
                this.children = S25Util.array.getByProp(this.allTypes, "parent_id", this.typeId);
                this.detectChanges();
                S25LoadingApi.destroy(this.elementRef.nativeElement);
            });
        });
    }

    getAllEventTypes() {
        return EventService.getAllEventType().then((data) => {
            data = S25Util.array.forceArray(data);
            this.allTypes = data;
            this.cabinets = data.filter((i: any) => i.parent_id === 0 && i.node_type === "C");
            this.cabinets = this.cabinets.map((obj: any) => {
                return { itemName: obj.type_name, itemId: obj.type_id };
            });
            this.detectChanges();
        });
    }

    postEventType() {
        this.initFunc(); // reset some vars
        return EventService.postEventType().then((data) => {
            this.eventType = data as any;
            this.eventType.inheritance_rules = HierarchyUtil.inheritanceRules(); // set inheritanceRules default values
            this.detectChanges();
        });
    }

    getEventTypeById(id: any) {
        return EventService.getEventTypeById(id).then((data) => {
            this.eventType = data;
            this.detectChanges();
        });
    }

    initFunc() {
        this.showSubFolder = false;
        this.hasSubFolder = false;
        this.toggleFolderLevel = false;
    }

    onChangeName(e: any) {
        this.eventType.type_name = e.target.value;
        this.detectChanges();
    }

    // made these functions to use with the create folder from cabinet button; not in use
    // createNewFolderType(cabinet: any) {
    // 	console.log(cabinet);
    // 	this.type = "F";
    // 	this.isEdit = false;
    // 	this.chosenCabinet = cabinet.type_id;
    // 	this.parentId= cabinet.type_id;
    // 	// this.eventType.parent_id = cabinet.type_id;
    // 	this.parentName = cabinet.type_name;
    // 	this.ngOnInit();
    // 	this.cd.detectChanges();
    // }

    // onSelectCabinet(id : number, name: string)  {
    // 	this.eventType.parent_id = id;
    // 	this.showSubFolder = S25Util.array.getByProp(this.allTypes, "parent_id", id);
    // 	this.folders = this.allTypes.filter((i : any) =>  i.parent_id === id && i.node_type === 'F');
    // 	this.folders = this.folders.map((obj:any) => {return {itemName: obj.type_name, itemId: obj.type_id}});
    // }

    onChangeCabinet(e: any) {
        this.eventType.parent_id = e.itemId;
        this.showSubFolder = S25Util.array.getByProp(this.allTypes, "parent_id", e.itemId);
        this.folders = this.allTypes.filter((i: any) => i.parent_id === e.itemId && i.node_type === "F");
        this.folders = this.folders.map((obj: any) => {
            return { itemName: obj.type_name, itemId: obj.type_id };
        });
        let el: any = "s25-ng-hierarch-dropdown";
        DropdownApi.refresh(el, this.folders);
        this.chosenFolder = "";
        this.detectChanges();
    }

    onChangeFolder(e: any) {
        this.eventType.parent_id = e.itemId;
    }

    getSubFolder(event: any) {
        this.toggleFolderLevel = event;
        !this.toggleFolderLevel
            ? (this.eventType.parent_id = this.chosenCabinet.itemId)
            : this.chosenFolder.itemId
              ? (this.eventType.parent_id = this.chosenFolder.itemId)
              : "";
    }

    getParentId(id: any) {
        this.parentId = id;
    }

    view() {
        this.location.back();
    }

    save() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        this.validate();
        if (this.valid) {
            let saveMsg = "ET_I_CREATED";
            this.isEdit ? (saveMsg = "ET_I_SAVED") : "";
            this.eventType.node_type = this.type;
            this.eventType.defn_state = 1;
            this.isEdit ? (this.eventType.status = "mod") : (this.eventType.status = "new");
            delete this.eventType.custom_attributes;
            delete this.eventType.contact_role;
            delete this.eventType.categories;
            delete this.eventType.notifications;
            delete this.eventType.class_name;
            delete this.eventType.favorite;
            delete this.eventType.last_mod_dt;
            delete this.eventType.last_mod_user;
            delete this.eventType.node_type_name;
            delete this.eventType.routing_rules;
            delete this.eventType.sort_order;
            delete this.eventType.routing_rules;
            delete this.eventType.tag_value;
            delete this.eventType.reports;
            delete this.eventType.requirements;

            LockService.lock(1005, 1101);
            EventService.putEventType(this.eventType.type_id, this.eventType, saveMsg).then((resp) => {
                if (resp && resp.success) {
                    this.loading = false;
                    LockService.delete(1101, 1005);
                    this.type = "view";
                    if (this.eventType.node_type && this.eventType.type_id) {
                        //return to hierarchy on save with new folder/cabinet highlighted
                        let hierarchyUrl =
                            HierarchyUtil.getCompUrl("hierarchy") + "/1/view/E/" + this.eventType.type_id;
                        window.location.assign(hierarchyUrl);
                    }
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                    console.log("Update done");
                    this.detectChanges();
                    //this.isEdit ? this.detectChanges() : window.location.reload();
                }
            });
        }
    }

    cancel() {
        this.goToHierarchy(this.typeId);
        /*
		this.init = false;  // set back to false, tried MultiselectApi.refresh but did not make changes
		this.initFunc();
		this.ngOnInit();			
		if(!this.isEdit || this.isEdit === 'undefined'){
			this.type = "view";
			HierarchyApi.refresh(this.elementRef.nativeElement);
		}
		this.detectChanges();
          setTimeout(() => {         
               this.location.back();
          }, 120); 	
          */
    }

    goToHierarchy(id: any) {
        id === "undefined" || !id ? (id = 1) : "";
        let hierarchyUrl = HierarchyUtil.getCompUrl("hierarchy") + "/1/view/E/" + this.eventType.type_id;
        window.location.assign(hierarchyUrl); // assign url and reload
        //window.location.reload();
    }

    delete() {
        let payload = {
            type_id: this.eventType.type_id,
            status: "del",
        };

        return AlertService.confirm("Are you sure you want to delete this " + this.name.toLowerCase() + " type?").then(
            (answer: boolean) => {
                if (answer) {
                    LockService.lock(1005, 1101);
                    return EventService.putEventType(this.eventType.type_id, payload, "ET_I_DELETED").then((resp) => {
                        if (resp && resp.success) {
                            LockService.delete(1101, 1005);
                            alert(this.name + " type has been deleted.");
                            let hierarchyUrl = HierarchyUtil.getCompUrl("hierarchy");
                            window.location.assign(hierarchyUrl);
                        }
                    });
                }
            },
        );
    }

    confirm() {
        this.deleteConfirmed = true;
    }

    onChangeDeleteConfirm(e: any) {
        if (e === "confirmed") {
            this.delete();
        } else {
            this.deleteConfirmed = false;
        }
    }

    copy() {
        //ANG-3822 make sure no event type name NOT in use
        let find = this.allTypes.find((a: any) => {
            return a && a.type_name === "Copy of " + this.eventType.type_name;
        });

        if (find) {
            alert(
                "Copy of " +
                    this.eventType.type_name +
                    " has already been used for another type.  Please specify a unique name for the type.",
            );
        } else {
            return EventService.postEventType().then((data) => {
                this.eventType.type_id = data.type_id;
                this.eventType.type_name = "Copy of " + this.eventType.type_name;
                this.isEdit = false;
                this.type === "F" ? (this.isCopy = true) : "";
                this.detectChanges();
                this.save();
            });
        }
    }

    deactive() {
        let payload = {
            type_id: this.eventType.type_id,
            type_name: this.eventType.type_name,
            node_type: this.eventType.node_type,
            defn_state: 0,
            status: "mod",
        };

        return AlertService.confirm("Are you sure you want to deactive this " + this.name + "?").then((answer) => {
            if (answer) {
                LockService.lock(1005, 1101);
                return EventService.putEventType(this.eventType.type_id, payload, "ET_I_SAVED").then((resp) => {
                    if (resp && resp.success) {
                        this.eventType.defn_state = 0;
                        LockService.delete(1101, 1005);
                        alert(this.name + " has been deactivated.");
                    }
                });
            }
        });
    }

    validate() {
        this.msg = "";
        if (!this.eventType.parent_id && !this.isEdit && this.type === "F") {
            this.msg = "Please select a cabinet";
            alert(this.msg);
            this.valid = false;
            S25LoadingApi.destroy(this.elementRef.nativeElement);
        }

        if (!this.eventType.type_name) {
            this.msg = "Event type name is required";
            alert(this.msg);
            this.valid = false;
            S25LoadingApi.destroy(this.elementRef.nativeElement);
        }
        return;
    }

    detectChanges() {
        try {
            setTimeout(() => {
                this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
            }, 60);
        } catch (error: any) {}
    }
}
