//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { Location } from "@angular/common";
import { EventService } from "../../services/event.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { LockService } from "../../services/lock.service";
import { PreferenceService } from "../../services/preference.service";

@TypeManagerDecorator("s25-ng-checkbox-template")
@Component({
    selector: "s25-ng-checkbox-template",
    template: `
        @if (this.loading) {
            <div>Loading....</div>
        }
        @if (this.init) {
            <div>
                <s25-ng-checkbox
                    [(modelValue)]="this.value"
                    (modelValueChange)="this.onChange()"
                    labelId="id{{ this.typeId }}"
                    [labelClass]="'inline'"
                ></s25-ng-checkbox>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25CheckboxTemplateComponent implements OnInit {
    @Input() type: any;
    @Input() typeId: any;
    @Input() value: any;
    @Input() abridgedPref: any;
    @Input() data: any;
    init = false;
    loading = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
        private location: Location,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.loading = true;
        this.cd.detectChanges();
        this.zone.run(() => {
            this.init = true;
            this.loading = false;
            this.cd.detectChanges();
        });
    }

    onChange() {
        this.loading = true;
        this.cd.detectChanges();
        if (this.type === "status") {
            let payload = {
                crc: this.data.crc,
                defn_state: this.value ? 1 : 0,
                favorite: this.data.favorite,
                node_type: this.data.node_type,
                node_type_name: this.data.node_type_name,
                parent_id: this.data.parent_id,
                status: "mod",
                type_id: this.data.type_id,
                type_name: this.data.type_name,
            };
            LockService.lock(1005, 1101);
            return EventService.putEventType(this.data.type_id, payload, "ET_I_SAVED").then((resp) => {
                if (resp && resp.success) {
                    LockService.delete(1101, 1005);
                    this.loading = false;
                    this.cd.detectChanges();
                }
            });
        } else {
            if (this.value) {
                this.abridgedPref.push(this.typeId);
            } else {
                this.abridgedPref = this.abridgedPref.filter((i: any) => i !== this.typeId);
            }
            this.abridgedPref = S25Util.array.unique(this.abridgedPref);
            let prefValue = this.abridgedPref.toString();
            return PreferenceService.setPreference("config_ev_types", prefValue, "S").then((resp) => {
                this.loading = false;
                this.cd.detectChanges();
            });
        }
    }
}
