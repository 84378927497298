import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
    ViewEncapsulation,
} from "@angular/core";
import { EventService } from "../../services/event.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { DropdownApi } from "../s25-dropdown/dropdown.api";
import { S25SubfolderDropdownComponent } from "../s25-dropdown/single-select/s25.subfolders.dropdown.component";

@TypeManagerDecorator("s25-ng-create-subfolder")
@Component({
    selector: "s25-ng-create-subfolder",
    template: ` @if (init) {
        <div>
            <ng-template #subfolderDropdownInstance let-vars>
                @if (addSubFolder) {
                    <div [ngClass]="{ 'indented-subfolder': vars.dropdownItems === subFolders }">
                        <label class="c-margin-right--single c-margin-top--half">
                            @if (numSubFolders <= 1) {
                                <span class="ngBlock c-margin-bottom--quarter" id="cabinet"
                                    ><span class="ngBold folderLabel"> Select {{ vars.headerContent }} Folder </span>
                                    @if (numSubFolders) {
                                        <span></span>
                                    }
                                    <span class="c-required">- Optional</span>
                                </span>
                            }
                            <s25-ng-subfolders-dropdown
                                [(items)]="vars.dropdownItems"
                                [searchEnabled]="true"
                                [staticItems]="staticItems"
                                (chosenChange)="onChangeFolder($event)"
                            ></s25-ng-subfolders-dropdown>
                        </label>
                        @if (vars.headerContent === "Parent") {
                            <button class="aw-button aw-button--danger--transparent" (click)="handleRemove()">
                                Remove
                            </button>
                        }
                    </div>
                }
                @if (numSubFolders <= 1 && showButton) {
                    <button (click)="handleShowButton()" class="c-textButton c-margin-top--quarter">
                        Add a Nested Folder
                    </button>
                }
                @if (numSubFolders <= 1 && showButton) {
                    <span class="c-required">- Optional</span>
                }
            </ng-template>
            @if (numSubFolders === 0 || numSubFolders === 1) {
                <ng-container
                    *ngTemplateOutlet="
                        subfolderDropdownInstance;
                        context: {
                            $implicit: {
                                headerContent:
                                    (numSubFolders === 0 && !showButton) ||
                                    (numSubFolders === 1 && showButton) ||
                                    level1Active
                                        ? 'Parent'
                                        : 'Top-Level',
                                dropdownItems: folders,
                            },
                        }
                    "
                ></ng-container>
            }
            @if (addSubFolder && numSubFolders === 1 && !showButton) {
                <ng-container
                    *ngTemplateOutlet="
                        subfolderDropdownInstance;
                        context: {
                            $implicit: {
                                headerContent: 'Parent',
                                dropdownItems: subFolders,
                            },
                        }
                    "
                ></ng-container>
            }
            @if (eventTypes.length === 0) {
                <div class="ngBold ngRed">Unable to add folder as there are no folder types available</div>
            }
        </div>
    }`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25CreateSubfolderComponent implements OnInit {
    @Input() folders: any;
    @Input() setParent: (e: Event) => void;
    @Input() getDate: (startDate: string, endDate: string) => void;
    @Input() event: any;
    @Input() allTypes: any;
    @Input() staticItems: any;
    @Input() chosenCabinet: any;

    @Output() eventTypes = new EventEmitter<any>();

    @ViewChildren(S25SubfolderDropdownComponent) subFoldersDropdowns: QueryList<S25SubfolderDropdownComponent>;

    init: boolean = false;
    numSubFolders: 0 | 1 | 2 = 0;
    addSubFolder: boolean = false;
    showButton: boolean = true;
    subFolders: any;
    chosenFolder_lvl1: any;
    level1Active: boolean;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit() {
        this.init = true;
    }

    onChangeFolder(e: any) {
        if (e && e.itemId && e.itemId > 0) {
            this.setParent(e);

            if (this.numSubFolders === 0 || (this.chosenFolder_lvl1 && this.chosenFolder_lvl1 !== e)) {
                this.chosenFolder_lvl1 = e;

                return EventService.getCabinetFolders(e.itemId).then((data) => {
                    this.subFolders = S25Util.array.forceArray(data);
                    this.subFolders = this.subFolders.map((obj: any) => {
                        return {
                            itemName: obj.event_name,
                            itemId: obj.event_id,
                            typeId: obj.event_type_id,
                            startDate: obj.start_date,
                            endDate: obj.end_date,
                        };
                    });

                    if (this.numSubFolders === 0) {
                        // Level 1 folder selected for the first time
                        this.numSubFolders++;
                        this.showButton = true;
                        this.cd.detectChanges();
                    } else {
                        // Level 1 folder selection changed - update level 2 folder drop down
                        const dropdown_lvl2: any = this.subFoldersDropdowns.last;
                        DropdownApi.refresh(dropdown_lvl2, this.subFolders);
                        dropdown_lvl2.chosen = undefined;
                    }
                });
            }
        } else {
            this.handleRemove();
        }
    }

    handleShowButton() {
        this.addSubFolder = true;
        this.showButton = false;

        if (this.numSubFolders === 1) this.level1Active = false;
    }

    handleRemove() {
        if (
            (this.numSubFolders === 0 && !this.showButton) ||
            (this.numSubFolders === 1 && this.showButton) ||
            this.level1Active
        ) {
            this.setParent(this.chosenCabinet);
            this.addSubFolder = false;
            this.showButton = true;
            this.numSubFolders = 0;
            this.chosenFolder_lvl1 = null;
            this.level1Active = false;
        } else if (this.numSubFolders === 1 && !this.showButton) {
            this.setParent(this.chosenFolder_lvl1);
            this.numSubFolders = 1;
            this.showButton = true;
            this.level1Active = true;
        }
    }
}
